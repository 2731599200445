.sliderShow{
	width:100%;
	height:600px;
	margin: 0 auto;
}
.entireContent {
    margin: auto;
    width: 50%;
    perspective: 1000px;
    position: relative;
    padding-top: 80px;
 }
  
.contentCarrousel {
    width: 100%;
    position: absolute;
    float: right;
    animation: rotar 60s infinite linear;
    transform-style: preserve-3d;
}

.contentCarrousel:hover {
    animation-play-state: paused;
    cursor: pointer;
}
  
.contentCarrousel figure {
    width: 100%;
    height: 300px;
    border: 1px solid #cae7f9;
    overflow: hidden;
    position: absolute;
    
}

/* 210px */
/* 190px */
  .contentCarrousel figure:nth-child(1) {
    transform: rotateY(0deg) translateZ(230px); 
    
  }  .contentCarrousel figure:nth-child(2) {
    transform: rotateY(120deg) translateZ(230px); 
  }  .contentCarrousel figure:nth-child(3) {
    transform: rotateY(240deg) translateZ(230px); 
  }  .contentCarrousel figure:nth-child(4) {
    transform: rotateY(360deg) translateZ(230px); 
  }  


  .shadow {
      position: absolute;
      box-shadow: 0px 0px 20px 0px #000;
      border-radius: 1px;
  }
  
  .contentCarrousel img {
    image-rendering: auto;
    transition: all 300ms;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .contentCarrousel img:hover {
    transform: scale(1.2);
    transition: all 300ms;
  }
  .imgA{
    transform: scale(1.2);
    margin-top: -10px;
  }
  .contentCarrousel  figcaption {
    display: block;
    background-color: #cae7f9;
    color: #000;
    padding: 8px;
    text-align: center;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
 
  @keyframes rotar {
    from {
      transform: rotateY(0deg);
     
    } to {
      transform: rotateY(360deg);
      
    }
  }