footer{left:0;bottom:0;width:100%;background-color:#faf9f9}
.footer-top{max-width:1200px;display:flex;justify-content:space-between;margin:auto}
.footer-logo{width:20%;padding:40px}
.footer-container{width:100%;display:flex;justify-content:space-between;margin:auto;padding:40px}
.f1{width:20%}.f2{width:30%}.f3{width:22%}.f4{width:23%}
.footer-link h4{font-size:19px;padding-bottom:15px;font-weight:700;color:#211f48 }
.footer-link h5{font-size:16px;color:#211f48;padding:5px 0 5px 0;font-weight: 400;}
.footer-link a{color:#211f48}.footer-icon{display:flex;margin:auto;padding:10px 0 0 0}
.footer-icon-box{width:30px;height:30px;border:1px solid #d19100 ;border-radius:50%;padding:5px;margin-right:10px}
.footer-icon-box a{color:#d19100 ;margin-left:2px}
.footer-bottom{background-color:#000;color:#fff;align-items:center;text-align:center;padding:20px;margin-top:30px}
.footer-bottom p{color:#b8b4b4}
.footer-bottom span{color:#fff}
.footer-bottom{background-color:#000;color:#fff;align-items:center;text-align:center;padding:20px}
.footer-bottom p{color:#b8b4b4}
.footer-bottom span{color:#fff}
@media only screen and (max-width:768px){.footer-top{width:100%;flex-direction:column;margin:auto}.footer-logo{width:100%;display:flex;margin:auto}.footer-logo img{width:40%;margin:auto}.footer-container{width:100%;flex-direction:column;align-items:center}.footer-link{width:100%;text-align:center;margin:10px}.footer-icon{display:inline-flex}}