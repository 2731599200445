@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merienda:wght@400;700&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto');
@import url('https://fonts.googleapis.com/css2?family=Montserrat');
@import url('https://fonts.googleapis.com/css2?family=Lato');
@import url('https://fonts.googleapis.com/css2?family=Merriweather');


* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: 'Infra', sans-serif;
	/*-webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
   user-select: none;*/
}

 
@font-face {
	font-family: 'Pacifico';
	font-style: normal;
	font-weight: 400;
	src: local('Pacifico Regular'), local('Pacifico-Regular'),
	  url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
		format('woff2');
	font-display: swap;
  }
li {
	list-style-type: none;

}

a {
	text-decoration: none;
	color: black;
}

a:hover {
	color: #3EADE0;
}

img {
	width: 100%;
	height: auto;
}


.modal {
	display: none;
	/* Hidden by default */
	position: fixed;
	/* Stay in place */
	z-index: 10;
	/* Sit on top */
	padding-top: 130px;
	/* Location of the box */
	left: 0;
	top: 0;
	right: 0;
	width: 768px;
	/* Full width */
	margin: auto;
	height: 100%;
	/* Full height */
	overflow: auto;
	/* Enable scroll if needed */
	/* Black w/ opacity */
}

/* Modal Content */
.modal-content {
	background-color: #fefefe;
	margin: auto;
	padding: 20px;
	border: 1px solid #888;
	width: 80%;
}

/* The Close Button */
.close {
	color: #aaaaaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: #000;
	text-decoration: none;
	cursor: pointer;
}

.modal-content h2 {
	font-size: 18px;
	font-weight: 600;
	color: #3EADE0;
}

.modal-content p {
	font-size: 15px;
	padding-top: 5px;

}

.modal-content form {
	margin-top: 10px;

}

.modal-content label {
	position: relative;
	display: block;
	margin-top: 20px;
	font-size: 16px;
	font-weight: 500;
	color: #000;
}

.modal-content input {
	display: block;
	height: 43px;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.07);
	border-radius: 3px;
	border: none;

	box-shadow: inset 2px 2px 5px #babecc,
		inset -5px -5px 10px #ffffff73;
	margin-top: 20px;

	font-size: 15px;
	font-weight: 300;
}

.modal-content ::placeholder {
	color: #4b4e4d;
	padding-left: 10px;
}

.form-text {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-text-content {
	width: 48%;
}

.modal-content textarea {
	display: block;
	height: 60px;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.07);
	border-radius: 3px;
	border: none;

	box-shadow: inset 2px 2px 5px #babecc,
		inset -5px -5px 10px #ffffff73;
	margin-top: 20px;

	font-size: 15px;
	font-weight: 300;
}

.modal-content textarea::placeholder {

	padding-top: 20px;
}

.modal-content button {
	width: 100%;
	margin-top: 35px;
	padding: 12px;
	background: #dde1e7;
	border: none;
	font-size: 17px;
	font-weight: 600;
	margin-bottom: 32px;
	box-shadow: -4px -4px 7px #fffdfdb7,
		3px 3px 5px rgba(94, 104, 121, 0.388);
	cursor: pointer;
}

.modal-content button:hover {
	box-shadow: inset -3px -3px 7px #ffffffb0, inset 3px 3px 5px rgba(94, 104, 121, 0.692);
}





.whatsapp {
	position: fixed;
	width: 50px;
	height: 50px;
	bottom: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 30px;
	box-shadow: 3px 4px 3px #999;
	right: 15px;
	z-index: 100;
	cursor: pointer;
}

.whatsapp:hover {
	color: #fff;
}

.my-float {
	margin-top: 10px;
}

#sucessmsg {
	display: none;
}

.popup-msg {


	position: fixed;
	/* Stay in place */
	z-index: 10;
	/* Sit on top */
	padding: 0 30px 30px;
	/* Location of the box */
	left: 0;
	top: 0;
	right: 0;
	width: 400px;
	/* Full width */
	margin: auto;
	margin-top: 180px;

	background-color: rgb(236, 236, 236);
	border-radius: 6px;


	text-align: center;

	color: #333;

}

.popup-msg img {
	width: 100px;
	margin-top: -50px;
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.popup-msg h2 {
	font-size: 38px;
	font-weight: 500;
	margin: 30px 0 10px;
}

.popup-msg button {
	width: 100%;
	margin-top: 50px;
	padding: 10px 0;
	background-color: #3EADE0;

	color: #fff;
	border: 0;
	outline: none;
	font-size: 18px;
	border-radius: 4px;
	cursor: pointer;
	box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}

.page404 h1 {
	display: flex;
	margin: auto;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 100px;
	margin-top: 40px;
	color: #3EADE0;

}

.page404 h2 {
	display: flex;
	margin: auto;
	text-align: center;
	justify-content: center;
	align-items: center;
	font-size: 32px;
	margin-top: 10px;
	color: #3EADE0;

}

.btn404 {
	display: flex;
	margin: auto;
	justify-content: center;
	align-items: center;
	color: white;
	background-color: #3EADE0;
	margin-top: 40px;
	margin-bottom: 40px;
	border-radius: 20px;
	padding: 10px;
	cursor: pointer;

}

.btn404:hover {
	background-color: #111E54;

}



.breadcrumb {
	position: sticky;
    background-color:  #fff;
	top: 170px;
	width: 1200px;
	margin: auto;
    z-index: 2;
	align-items: center;
	list-style: none;
	

	
	
}

.breadcrumb li {
	display: inline;
	font-size: 16px;
	height: 60px;
	line-height: 60px;
}

.breadcrumb li+li:before {
	padding: 8px;
	color: black;
	content: ">";
}

.breadcrumb li a {
	color: #0275d8;
	text-decoration: none;
}

.breadcrumb li a:hover {
	color: #01447e;
	text-decoration: underline;
}
.table {
	border-collapse: collapse;
	width: 600px;
	border: #3EADE0 solid 1px;
	margin-left: 0px;
	margin-top: 20px;
}

.table th {
	background-color: #3EADE0;
	color: #fff;
	font-size: 20px;
	text-align: left;
	padding: 10px 10px 10px 10px;
}

.table td {
	padding: 15px;
	text-align: left;
	border-bottom: 1px solid #3EADE0;
	font-size: 18px;
}

.table ul {
	padding-left: 20px;
}

.table ul li {
	padding-left: 20px;
	padding-top: 10px;
	list-style: disc;

}

.table-td {
	padding-left: 52px !important;
}

td:first-child {
	font-weight: bold
}

td:first-child {
	font-weight: bold
}









@media only screen and (max-width: 1200px) {
	.popup-form-btn {
		justify-content: space-between;
	}

	.button {

		width: 48%;
	}
}

@media only screen and (max-width: 768px) {
	.popup-form-btn {
		flex-direction: column;
	}

	.button {

		width: 100%;
	}

	.modal {
		width: 100%;
	}

	.form-text {
		flex-direction: column;
	}

	.form-text-content {
		width: 100%;
	}
	.breadcrumb li {

		font-size: 10px !important;
	}



	.table {
		width: 100%;
		
	}
	.table td {
		padding: 15px;
		
	}

	.table ul {
		padding-left: 20px;
	}

	.table ul li {
		padding-left: 10px;
		padding-top: 10px;
		list-style: disc;
		text-align: left;

	}

	.table-td {
		padding-left: 52px !important;
	}
	.breadcrumb {
		position: sticky;
		background-color:  #fff;
		top: 345px !important;
		padding-left: 20px;
		width: 100%;
		z-index: 2;
		align-items: center;
		list-style: none;
	
	
		
		
	}
}