.slider {
    position: relative;
    width: 100%;
    height: 680px;
    overflow: hidden; /* Ensures video does not overflow the container */
}

#bg-video {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height:680px; 
    z-index: -1; /* Places the video behind other content */
    transform: translate(-50%, -50%);
    object-fit: cover; /* Ensures the video covers the entire area */
    background-color: gray; /* Fallback color */
}
@media only screen and (max-width: 768px) {
   
    #bg-video {
        width: 100%;
    }
}
    
.slider-content {
    position: relative;
    z-index: 1; /* Ensures content appears over the video */
    color: white; /* Style your content */
    text-align: center;
    padding-top: 250px; /* Center content vertically */
}

.SalesBtn2{
    border: 1px solid #1d1f48;
   

}
.subtit{
    color: red !important;
}