nav {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 10;
}
.navbar {
    max-width: 1200px;
    margin: auto;
    height: 85px;
    line-height: 85px;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.logo {
    margin: auto;
    width: 15%;
    height: 85px;
}
.logo img{
    width: 100%;
    height: auto;
  
}
.nav-links {
    display: flex;
    justify-content: flex-end;
    width: 85%;
}
.nav-links li {
    font-weight: 500;
    color: #1d1f48;
    font-size: 16px;
    padding-left: 40px;
    cursor: pointer;
    text-decoration: none;
}
.nav-links li a{
    color: #1d1f48;

}
.active {
    color: #d19100 !important;
}
.dropdown-sub.active {
    color: #000;
  }
.selected{
    color: #3eade0; 
}
button {
    border: none;
    background: none;
    font-size: 20px;
}
.mobile-menu-icon {
    display: none;
}
.phone-icon{
    display: none;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 300px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    line-height: 40px;
  }
  
  .dropdown-content a {
  
    padding: 1px 16px;
    text-decoration: none;
    display: list-item;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
 
  .dropdown-content li{
    font-weight: 700;
    color: #000;
    font-size: 14px;
    padding-left: 0px;
    cursor: pointer;
    text-decoration: none;
  }



.dropdown-content-sub{
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    width: 350px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    line-height: 40px;
    top: 0;
    margin-left: 300px;
}


.dropdown-content-sub a {
  
    padding: 1px 16px;
    text-decoration: none;
    display: list-item;
  }
  
  .dropdown-content-sub a:hover {background-color: #ddd;}
  
  .dropdown-sub:hover .dropdown-content-sub {display: block;}
 
  .dropdown-content-sub li{
    font-weight: 700;
    color: #000;
    font-size: 18px;
    padding-left: 0px;
    cursor: pointer;
    text-decoration: none;
  }




  @media only screen and (max-width: 1024px){
    .logo {
        width: 20%;  
    }
  }


@media only screen and (max-width: 1080px) {
    .logo {
        width: 25%;
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        
        
    }
    .navbar {
       width: 100%;
        margin: auto;
        height: 85px;
        line-height:40px;
       
    }
    .nav-links {
        display: none;
    }
    .nav-links-mobile {
        position: absolute;
        display: block;
        list-style: none;
        left: 0;
        top: 85px;
        transition: all 0.5s ease-in-out;
        width: 100%;
        background-color: #fff;
    }
    ul li {
        width: 100%;
        transition: all 0.5s ease-in-out;
        text-align: center;
    }
    .mobile-menu-icon {
        position: absolute;
        display: block;
        right: 20px;
    }
    
.dropdown-content {
    display: block;
    position: unset;
    background-color: #f1f1f1;
    width: 100%;
    box-shadow: none;
    z-index: 0;
  
    font-weight: 700;
    color: #000;
    font-size: 18px;
  }
  
  .dropdown-content a {
  
    padding: 1px 16px;
    text-decoration: none;
    display: list-item;
    font-weight: 400;
    color: #000;
    font-size: 15px;
  }
  
  .phone-icon{
    display: none;
    margin-top: -50px;
  }


  .nav-links-mobile li:nth-child(2) .dropdown-content, .nav-links-mobile li:nth-child(3) .dropdown-content, .nav-links-mobile li:nth-child(4) .dropdown-content{
        display: none;
  }

   
}

.ContactBtn{
    background-color: #d19100;
    padding: 11px 25px;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 12px;
    font-weight: 400;
    color: #1d1f48;
}
.SalesBtn{
    background-color: transparent;
    padding: 11px 19px;
    font-size: 18px;
    color: white;
    padding: 10px 24px;
    border: 1px solid white;
    border-radius: 25px;
}

@media only screen and (max-width: 481px){
    .logo {
        width: 45%;   
    }
}