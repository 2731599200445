.slider{
    position: relative;
    /* background: linear-gradient(to right,  #cae7f9 50%, transparent); */
    width: 100%;
    height:580px; 
}
.sliderContainer{
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 540px;
    margin: auto;
    background-repeat: no-repeat;
    background-size: cover;
   
}
.sliderContent{
    width: 1200px;
    height: 540px;
    align-items: center;
    text-align: center;
    margin: auto;
    /* padding-right:600px; */
    padding-top:100px;
}
@media (min-width: 768px) and (max-width: 1250px) {
    .sliderContent{
        height: 480px !important;
    }
}
.sliderContent p{
   color: white;
    font-size: 20px;
    padding: 10px 0px;
    font-weight: 400;
  
   
}
.sliderContent p:nth-child(2){
    font-size: 20px;
    color: white;
    font-weight:normal;
    text-align: center;
}
.sliderContent p:nth-child(3){
    font-size: 22px;
    color: #000;
    font-weight:normal;
    text-align: center;
}

@media  only screen and (max-width: 1024px){
    .sliderContent{
        width: 1024px;
        padding:100px 100px 0px 30px ;
    }
    .sliderContent p:nth-child(2){
        font-size: 25px;
        font-weight:normal;
    }
    .sliderContent p:nth-child(3){
        font-size: 22px;
        font-weight:normal;
        text-align: left;
    }
}
@media  only screen and (max-width: 768px){
    .slider{
       background: none;
    }
    .sliderContainer{
        width: 100%;  
        z-index: -1; 
    }
    .sliderContent{
        width: 100%;
        padding: 60px 20px;
        margin: auto;
        height: auto;
    }
    .sliderContent p{
      
        text-shadow: 0 0 20px #323131;
    }
    .slider{
        height:auto; 
    }
  
}